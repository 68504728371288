import React, { useEffect, useState } from "react";

import useFirebase from "./Hooks/useFirebase";

type PropsType = {
  p_preset: boolean;
};

export const Page_Login = (props: PropsType) => {
  // console.log("------------------------ Page_Login , props", props);

  const [LoginGUI, LogoutGUI, firebase_UserInfo] = useFirebase("Page_Login");

  return (
    <>
      {props.p_preset === true ? (
        <>Nevigating...</>
      ) : (
        <>
          <br />
          <br />
          <div className="glo_flex_column">
            <br />
            <br />
            <h1>ECS Login</h1>
            <div>Eclipse Cafe Soulution</div>

            {/* {console.log("props.p_preset", props.p_preset)} */}
            {props.p_preset === false ? (
              <>
                <p>
                  Your are <b>not Authorized</b>
                </p>
                <LoginGUI />
              </>
            ) : (
              <>
                <LogoutGUI />
              </>
            )}
          </div>

          {/* <div>{firebase_UserInfo?.displayName}</div> */}
          {/* <div>{firebase_UserInfo?.email}</div> */}
          {/* <img src={firebase_UserInfo?.photoURL} /> */}

          {/* <button
      onClick={() => {
        console.log("sss", firebase_UserInfo);
      }}
    >
    get Firebase User Info
    </button> */}
          {/* <br /> */}
          {/* <br /> */}

          {/* <br /> */}
          {/* <br /> */}
        </>
      )}
    </>
  );
};

export default Page_Login;
