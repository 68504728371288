import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import authentication from "../Config/firebase-config";
import CTX from "../Hooks/useContext";

import { Button } from "@mantine/core";

// type propsType = {
//   callFrom: any;
// };

export const useFirebase = (callFrom: string) => {
  const ctx = useContext(CTX);

  // console.log("--------------------------- useFirebase , ctx", {
  //   callFrom,
  //   ctx: ctx.current,
  // });

  // check current user (auto)
  const [firebase_UserInfo, setFirebase_UserInfo] = useState<any>([]);

  const runOnce = useRef(true);
  useEffect(() => {
    onAuthStateChanged(authentication, (currentUser) => {
      setFirebase_UserInfo(currentUser);

      // if (
      //   currentUser !== null &&
      //   currentUser !== undefined &&
      //   ctx.current !== null &&
      //   ctx.current !== undefined
      // ) {
      // console.log("FB - currentUser", currentUser);
      // ctx.current = { authentication: true, firebaseInfo: currentUser };

      // }
      if (runOnce.current === true) {
        // console.log("FB -  currentUser", currentUser);
        runOnce.current = false;
      }

      // auto nevigate to home or login page
      // if (currentUser !== null) {
      //   window.location.replace("/#/home");
      // }
      //else {
      //   window.location.replace("/#/");
      // }
    });
  }, [firebase_UserInfo?.length !== 0]);

  // sign out
  const LogoutGUI = () => {
    if (firebase_UserInfo !== null) {
      return (
        <div className="glo_flex_row glo_relative_right ">
          <img
            src={firebase_UserInfo?.photoURL}
            style={{
              borderRadius: "100rem",
              marginRight: ".5rem",
              marginTop: ".2rem",
            }}
            height="50rem"
            className="glo_hide_on_portrait"
          />
          <div
            style={{
              color: "white",
              marginRight: "1rem",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginTop: "0rem",
              textShadow: "1px 1px 5px  black",
            }}
            className="glo_hide_on_portrait"
          >
            {`${ctx.current?.DB_Users?.u_custom_name} (${
              firebase_UserInfo?.displayName
            })
            [
              ${ctx.current?.DB_Users?.u_auth == "0" ? "0 Not Allow" : ""}
              ${ctx.current?.DB_Users?.u_auth == "1" ? "1♞ Staff" : ""}
              ${ctx.current?.DB_Users?.u_auth == "2" ? "2♝ Supervisor" : ""}
              ${ctx.current?.DB_Users?.u_auth == "3" ? "3♖ Boss" : ""}
              ${ctx.current?.DB_Users?.u_auth == "4" ? "4♛ Eclipse Staff" : ""}
              ${ctx.current?.DB_Users?.u_auth == "5" ? "5♔ Admin" : ""}
              
              ]`}
            {/* {console.log("ctx.current", ctx.current)} */}
          </div>
          <Button
            size="xl"
            color="red"
            variant="subtle"
            onClick={() => {
              signOut(authentication);
              window.location.reload();
            }}
          >
            Logout
          </Button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  // log in
  const [btnLoggingin, setBtnLoggingin] = useState(false);
  const LoginGUI = () => {
    if (firebase_UserInfo === null) {
      return (
        <div>
          {btnLoggingin === false ? (
            <div className="glo_flex_row  ">
              <Button
                size="xl"
                color="lime"
                onClick={() => {
                  setBtnLoggingin(true);

                  const provider = new GoogleAuthProvider();
                  signInWithPopup(authentication, provider)
                    .then((res: any) => {
                      // console.log("re", res);
                      // console.log("re", res?.user?.displayName);
                      // setFirebase_userInfo(res);
                      // idToken.current = res._tokenResponse.idToken;
                      // console.log("idToken", res._tokenResponse.idToken);

                      if (res?.user?.displayName != "") {
                        // (window as Window).location = "/#/";
                      }
                    })
                    .catch((err) => {
                      // console.log("re-err", err);
                    });
                  // console.log("wwww");
                }}
              >
                Login with google
              </Button>
            </div>
          ) : (
            <h1>Loading...</h1>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  };

  return [LoginGUI, LogoutGUI, firebase_UserInfo];
};

export default useFirebase;
