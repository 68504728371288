import React, {
  useState,
  useEffect,
  useMemo,
  ReactNode,
  useContext,
} from "react";
import axios from "axios";

// import useFirebase from "../Hooks/useFirebase";
import CTX from "../Hooks/useContext";

import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";

import moment from "moment";

import { Box, Button, Menu, Text, Title, Checkbox } from "@mantine/core";

import { RxUpdate } from "react-icons/rx";
import { RiInstallFill } from "react-icons/ri";

// const REACT_APP_ECLIPSE_API = import.meta.env.VITE_REACT_APP_ECLIPSE_API;
const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

type TableData = {
  id: string;
  game_id: string;
  game_name: string;
  game_category: string;
  game_size: string;
  request_type: string;
  request_by: string;
  request_by2: string;
  request_cafes: string;
  request_dt: string;
  request_status: string;
  edit_by: string;
  edit_dt: string;
};

type PropsType = {
  selectedCafeName: string[];
};

export const Page_GameStatusLog = (props: PropsType) => {
  const ctx = useContext(CTX);

  // console.log("props", props);
  const selectedCafeName = props.selectedCafeName
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .slice(1);
  // const [LoginGUI, LogoutGUI, firebase_UserInfo] = useFirebase();

  useEffect(() => {
    // console.log("ctx.current", ctx.current);
    // console.log("ctx.current", ctx.current?.DB_Users?.u_auth);
    // console.log(
    //   "ctx.current",
    //   ctx.current?.DB_Users?.u_auth === "2" ||
    //     ctx.current?.DB_Users?.u_auth === "3"
    // );
  }, []);

  useEffect(() => {
    if (selectedCafeName.length === 0) return;
    getData();
    // console.log("useEffect - selectedCafeName", selectedCafeName);
  }, []);

  const getData = () => {
    get_DB_GameRequestLog(selectedCafeName);
  };

  const [DB_GameRequetLog, setDB_GameRequestLog] = useState<any>([]);
  const get_DB_GameRequestLog = (source: string[]) => {
    // console.log("source", source);
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_mon_gamelist__requestlist`)
      .then((res) => {
        setDB_GameRequestLog(res.data);
        // console.log("get_DB_GameRequestLog", res.data);
      });
  };

  const set_DB_GameRequest = (
    request_status: string,
    edit_by: string,
    id: string
  ) => {
    // console.log("set_DB_GameRequest" );
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_mon_gamelist__requestlist_byid`,
        {
          request_status,
          edit_by,
          id,
        }
      )
      .then((res) => {
        console.log("set_DB_GameRequest", res.data);
        if (res.data.affectedRows > 0) getData();
      });
  };

  const columns = useMemo<MRT_ColumnDef<TableData>[]>(
    () => [
      {
        accessorKey: "game_name",
        header: "Game Name",
        minSize: 20,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <b>{`[${row.original.game_id}] ${row.original.game_name}`}</b>
            <div>{row.original.game_category}</div>
            {isNaN(parseFloat(row.original.game_size)) === false ? (
              <div>{`📂 ${(parseFloat(row.original.game_size) / 1024).toFixed(
                2
              )} GB`}</div>
            ) : (
              "📂"
            )}
          </>
        ),
      },

      {
        accessorKey: "request_dt",
        header: "Request at",
        minSize: 100,

        Cell: ({ renderedCellValue, row }) => (
          <div>
            <div>
              <b>{`⏳${moment(
                row.original.request_dt?.toString()
              ).fromNow()}`}</b>
            </div>
            <div>{row.original.request_dt}</div>
          </div>
        ),
      },

      {
        accessorKey: "game_category",
        header: "Category",
        minSize: 100,

        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      {
        accessorKey: "request_type",
        header: "Request Type",
        minSize: 100,

        Cell: ({ renderedCellValue, row }) => (
          <>
            {row.original.request_type.includes("request update") ? (
              <div style={{ color: "red" }}>{row.original.request_type}</div>
            ) : (
              <div style={{ color: "orange" }}>{row.original.request_type}</div>
            )}
          </>
        ),
      },
      {
        accessorKey: "request_by",
        header: "Request By",
        minSize: 100,

        Cell: ({ renderedCellValue, row }) => (
          <div>
            <div>{row.original.request_by2}</div>
            <div>{row.original.request_by}</div>
          </div>
        ),
      },

      {
        accessorKey: "request_cafes",
        header: "Cafes",
        minSize: 100,

        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      {
        accessorKey: "request_status",
        header: "♛",
        maxSize: 70,

        Cell: ({ renderedCellValue, row }) => (
          <div>
            <Checkbox
              disabled={
                ctx.current?.DB_Users?.u_auth >= 4 ? false : true
                // ctx.current?.DB_Users?.u_auth === "1" ||
                // ctx.current?.DB_Users?.u_auth === "0"
              }
              size="xl"
              color="lime"
              checked={Boolean(row.original.request_status)}
              onChange={(event) => {
                console.log("request_by", row.original.id);
                console.log("displayName", ctx?.firebase_UserInfo?.displayName);
                console.log(event.currentTarget.checked);
                set_DB_GameRequest(
                  event.currentTarget.checked.toString(),
                  ctx.current.firebase_UserInfo.email,
                  row.original.id
                );
              }}
            />
          </div>
        ),
      },
      {
        accessorKey: "edit_by",
        header: "Action by ",
        minSize: 100,

        Cell: ({ renderedCellValue, row }) => (
          <div>
            <div>{row.original.edit_by}</div>
            <div>{row.original.edit_dt}</div>
            {row.original.edit_dt !== null ? (
              <div>
                {`⌛${moment
                  .duration(
                    moment(row.original.edit_dt).diff(
                      moment(row.original.request_dt)
                    )
                  )
                  .humanize()}`}
              </div>
            ) : (
              ""
            )}
          </div>
        ),
      },
    ],
    []
  );

  //----------------- component func
  const formatDate = (x: ReactNode) => {
    let result = "";
    if (x === null || x === undefined) {
      result = "NA";
    } else if (x?.toString()?.includes("Updating")) {
      result = `Updating...`;
    } else {
      result = `${x} , ${moment(x?.toString()).fromNow()}`;
    }

    return result;
  };

  type FormatedDateType = {
    date: string;
    cafe?: string;
    game_id?: string;
    game_name?: string;
    game_category?: string;
  };
  const FormatedDate = (x: FormatedDateType) => {
    let result = <>---</>;

    if (x?.date === null || x?.date === undefined) {
      result = (
        <>
          <b>NA</b>
          <div
            className="glo_btn_normal"
            onClick={() => {
              // console.log("Request Install", x);
            }}
          >
            <RiInstallFill />
            Request Install
          </div>
        </>
      );
    } else if (x?.date?.toString()?.includes("Updating")) {
      result = <div className="tbl_updating">Updating...</div>;
    } else {
      result = (
        <>
          <div className="glo_flex_row">
            <div className="tbl_done">Done</div>
          </div>
          <b>{moment(x?.date?.toString()).fromNow()}</b>
          <div className="tbl_text_lightgray">{x?.date}</div>
        </>
      );
      //  `${x?.date} , ${moment(x?.date?.toString()).fromNow()}`;
    }

    return (
      <>
        <div>{result}</div>
        <div className="tbl_text_lightgray">{`[${x?.cafe}]`}</div>
      </>
    );
  };

  const [hidenColumns, sethidenColumns] = useState({
    game_category: false,
  });
  const [showTable, setShowTable] = useState(true);

  return (
    <div>
      <h1>Request Game Log</h1>

      {/* <button
        onClick={() => {
          console.log("first");
          sethidenColumns({ id: true });
        }}
      >
        get_DB_Data
      </button>
      <button
        onClick={() => {
          setShowTable(true);
        }}
      >
        show table
      </button>
      <button
        onClick={() => {
          setShowTable(false);
        }}
      >
        hide table
      </button> */}

      <br />

      {DB_GameRequetLog?.length !== 0 ? (
        <MantineReactTable
          columns={columns}
          data={DB_GameRequetLog}
          enableGrouping
          initialState={{
            expanded: true, //expand all groups by default
            // grouping: ["game_category"], //an array of columns to group by by default (can be multiple)
            pagination: { pageIndex: 0, pageSize: 100 },
            // sorting: [{ id: "id", desc: false }], //sort by state by default
            density: "xs",
            // showColumnFilters: true,
            showGlobalFilter: true,
            columnVisibility: hidenColumns,
            columnPinning: {
              left: ["game_name"],
            },
          }}
          // positionGlobalFilter="left"
          enableColumnResizing
          columnResizeMode="onChange" //default
          mantinePaginationProps={
            {
              // rowsPerPageOptions: ["500", "1000"],
            }
          }
        />
      ) : (
        "Loading...."
      )}
    </div>
  );
};

export default Page_GameStatusLog;
