import React, { useContext, useEffect, useRef, useState } from "react";

import { Navigate, Outlet } from "react-router";
import Page_Login from "../Page_Login";

import useFirebase from "../Hooks/useFirebase";
import useRoleAuth from "../Hooks/useRoleAuth";

import CTX from "../Hooks/useContext";

// const useAuth = (_u_auth: boolean) => {
//   const user = { loggedIn: true };
//   return user && user.loggedIn;
// };

// type propsType = {
//   P_isAuth: boolean;
// };

const ProtectedRoutes = () => {
  const ctx = useContext(CTX);
  // console.log("--------------------------- P_isAuth", props.P_isAuth);
  // console.log("--------------------------- ProtectedRoutes , ctx", ctx);

  // ---------------------------------------------------------  Firebase login
  const [LoginGUI, LogoutGUI, firebase_UserInfo] =
    useFirebase("ProtectedRoutes");
  // // ---------------------------------------------------------  DB user Auth

  const { DB_Users, setFB_Obj } = useRoleAuth();

  useEffect(() => {
    // console.log("useEffect - firebase_UserInfo", firebase_UserInfo);
    setFB_Obj(firebase_UserInfo);
  }, [firebase_UserInfo]);

  const [isAuth, setIsAuth] = useState(false);
  // const isAuth = useRef(false);
  useEffect(() => {
    // console.log("ctx.current.authentication ", ctx.authentication === true);
    // console.log("ctx.current.authorization", ctx.authorization === true);
    // if (ctx.authentication === true && ctx.authorization === true) {
    //   isAuth.current = true;
    // }

    // console.log("isAuth.current", isAuth);

    if (
      firebase_UserInfo?.length !== 0 &&
      DB_Users?.u_auth > 0 &&
      DB_Users?.archive !== 1 &&
      DB_Users !== undefined
    ) {
      // console.log("##### firebase_UserInfo", firebase_UserInfo);
      // console.log("##### DB_Users", DB_Users);
      ctx.current.authorization = true;
      ctx.current.firebase_UserInfo = firebase_UserInfo;
      ctx.current.authentication = true;
      ctx.current.DB_Users = DB_Users;
      setIsAuth(true);
      // console.log("########### ctx", ctx.current);
    }
  }, [firebase_UserInfo?.length !== 0, DB_Users?.length !== 0]);

  // const isAuth = useAuth(true);
  //(window.location.href = "/#/home")
  return isAuth ? <Outlet /> : <Page_Login p_preset={isAuth} />;
  // return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
