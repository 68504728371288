import React, { useState, useEffect, useRef, Children } from "react";
import axios from "axios";

import { useSearchParams } from "react-router-dom";

// ------------------------------------------------- MUI Table 1/3
import {
  Table,
  Button as ButtonMT,
  Checkbox,
  Tooltip,
  Spoiler,
} from "@mantine/core";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const Page_CCStatus = () => {
  const [searchParams] = useSearchParams();
  const param_cc = searchParams.get("cc"); //

  //  Get Data from DB -----------------------------------------------------------------------------------------
  const [db_AllCafeStatus, setDb_AllCafeStatus] = useState<any>([]);
  const get_DB_AllCafe_Status = async (
    date_start: any,
    date_until: any,
    get_InfoFrom: any
  ) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_careu__allcafe_status`, {
        date_start,
        date_until,
        get_InfoFrom,
        shownHiddenPC,
      })
      .then((res) => {
        setDb_AllCafeStatus(res.data);
        console.log("get_DB_AllCafe_Status", res.data);
      });
  };

  const [db_AllCafePCStatus, setDb_AllCafePCStatus] = useState<any>([]);
  const get_DB_AllCafePC_Status = async () => {
    console.log("p1");
    await axios
      //REACT_APP_ECLIPSE_API
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_careu__allcafepc__status`, {
        shownHiddenPC,
      })
      .then((res) => {
        setDb_AllCafePCStatus(res.data);
        console.log("get_DB_AllCafePC_Status", res.data);
      });
    console.log("p2");
  };

  //-------------------------------------------------------------------------- global var
  const CareUScanTick_Minute = useRef(15);

  const [showAdvaceInfo, setShowAdvaceInfo] = useState(true);
  const [shownHiddenPC, setShownHiddenPC] = useState(false);

  const [selected_Cafe, setSelected_Cafe] = useState("");

  const Filtered_AllCafe = () => {
    return db_AllCafeStatus?.filter((x: any) => {
      return (
        x?.cafe?.toString().toLowerCase() === param_cc?.toString().toLowerCase()
      );
    });

    // if (selected_Cafe === "" || showAdvaceInfo === true) {
    //   return db_AllCafeStatus;
    // }
    // if (selected_Cafe !== "" && showAdvaceInfo === false) {
    //   return db_AllCafeStatus?.filter((x: any) => {
    //     return x?.cafe == selected_Cafe;
    //   });
    // }
  };

  const tablerows = Filtered_AllCafe()?.map((x: any, xi: number) => (
    <tr
      key={xi}
      className="table_pcstatus_row"
      onClick={() => {
        console.log("cafe", x?.cafe);
        // get_DB_CafeHardware_byCafe(x?.cafe, shownHiddenPC);
        setSelected_Cafe(x?.cafe);
      }}
      style={{
        backgroundColor: selected_Cafe == x?.cafe ? "rgba(7, 85, 82, 0.9)" : "",
      }}
    >
      {/* Cafe */}
      <td className="glo_width100px">{`${x?.cafe}  (${
        db_AllCafePCStatus?.[9]?.filter((x2: any) => x2.cafe === x?.cafe)[0]
          ?.Total_PC
      })`}</td>

      {/* LAN 100M List Now!*/}
      <td className="glo_width150px">
        {db_AllCafePCStatus?.[8]
          ?.filter((y: any) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z: any, zi: number) => {
            return (
              <div key={zi}>
                <div
                  style={{
                    backgroundColor: "darkred",
                  }}
                >
                  <Custom_ToolTip
                    c_cafe={x?.cafe}
                    c_pc_name={z?.PC_Name}
                    pcinfo2={<>{x?.NIC_Speed}</>}
                  >
                    <div>{`🚨${z?.PC_Name}`}</div>
                  </Custom_ToolTip>
                </div>
              </div>
            );
          })}
      </td>

      {/* LAN 100M List Status*/}
      <td className="glo_width150px">
        {db_AllCafePCStatus?.[2]
          ?.filter((y: any) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z: any, zi: number) => {
            return (
              <div key={zi}>
                <div
                  style={{
                    backgroundColor: z?.LAN_Status_Pctg > 1 ? "darkred" : "",
                  }}
                >
                  <Custom_ToolTip
                    c_cafe={x?.cafe}
                    c_pc_name={z?.PC_Name}
                    pcinfo2={
                      <>
                        <div>
                          {`
                    ${(
                      (z?.LAN_100M_Count * CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.LAN_Total_Count * CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                        </div>

                        <div>
                          {`
               (${z?.LAN_100M_Count} /
                 ${z?.LAN_Total_Count}) Samples `}
                        </div>
                      </>
                    }
                  >
                    <div>{`🚨${z?.PC_Name},  ${z?.LAN_Status_Pctg}% `}</div>
                  </Custom_ToolTip>
                </div>
              </div>
            );
          })}
      </td>

      {/* VT Off Now */}
      <td className="glo_width150px">
        {db_AllCafePCStatus?.[7]
          ?.filter((y: any) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z: any, zi: number) => {
            return (
              <div key={zi}>
                <div
                  style={{
                    backgroundColor: "darkred",
                  }}
                >
                  <Custom_ToolTip
                    c_cafe={x?.cafe}
                    c_pc_name={z?.PC_Name}
                    pcinfo2={<>{z?.CPU_VirtualizationFirmwareEnable}</>}
                  >
                    {`🚨${z?.PC_Name} `}
                  </Custom_ToolTip>
                </div>
              </div>
            );
          })}
      </td>
      {/* VT Off Status */}
      <td className="glo_width150px">
        {db_AllCafePCStatus?.[1]
          ?.filter((y: any) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z: any, zi: number) => {
            return (
              <div key={zi}>
                <div
                  style={{
                    backgroundColor:
                      z?.CPU_VIR_Status_Pctg > 1 ? "darkred" : "",
                  }}
                >
                  <Custom_ToolTip
                    c_cafe={x?.cafe}
                    c_pc_name={z?.PC_Name}
                    pcinfo2={
                      <>
                        <div>
                          {`
                    ${(
                      (z?.CPU_VIR_OFF_Count * CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.CPU_VIR_Total_Count * CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                        </div>
                        <div>
                          {`
               (${z?.CPU_VIR_OFF_Count} /
                 ${z?.CPU_VIR_Total_Count}) Samples `}
                        </div>
                      </>
                    }
                  >
                    {`🚨${z?.PC_Name},  ${z?.CPU_VIR_Status_Pctg}% `}
                  </Custom_ToolTip>
                </div>
              </div>
            );
          })}
      </td>

      {/* Pc Offline List*/}

      <td className="glo_width100px">
        {db_AllCafePCStatus?.[0]
          ?.filter((y: any) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z: any, zi: number) => {
            return (
              <div
                key={zi}
                style={{
                  backgroundColor: z?.Day_OFF > 1 ? "darkred" : "",
                }}
              >
                <Custom_ToolTip
                  c_cafe={x?.cafe}
                  c_pc_name={z?.PC_Name}
                  pcinfo2={<></>}
                >
                  {`${z?.PC_Name} (${z?.Day_OFF} Days)`}
                </Custom_ToolTip>
              </div>
            );
          })}
      </td>

      {/* Pc Start Up Time */}

      <td className="glo_width100px">
        <div
          style={{
            backgroundColor:
              x?.start_uptime?.split("/")?.[0] > 0 ? "darkgreen" : "",
          }}
        >{`${x?.start_uptime?.split("/")?.[0]}pc =< 1min`}</div>
        <div
          style={{
            backgroundColor: x?.start_uptime?.split("/")?.[1] > 0 ? "" : "",
          }}
        >{`${x?.start_uptime?.split("/")?.[1]}pc =< 2min`}</div>
        <div
          style={{
            backgroundColor:
              x?.start_uptime?.split("/")?.[2] > 0 ? "darkred" : "",
          }}
        >{`${x?.start_uptime?.split("/")?.[2]}pc => 2min`}</div>
      </td>

      {/* GNOFOT */}
      {/* <td>
        <div className="glo_grid_3x3 glo_width300px">
          <div>{`${x?.PC_GUOFOT_Status_15min?.split("/")?.[0]} HC-Game`}</div>
          <div>{`${x?.PC_GUOFOT_Status_15min?.split("/")?.[2]} HC-APP`}</div>
          <div style={{ color: "lime" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[5]
          } PC ON`}</div>

          <div style={{ color: "cyan" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[1]
          } N-Game`}</div>
          <div style={{ color: "cyan" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[3]
          } N-APP`}</div>
          <div style={{ color: "cyan" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[4]
          } Free PC`}</div>

          <div></div>
          <div></div>
          <div style={{ color: "gold" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[6]
          } Total PC`}</div>
        </div>
      </td> */}

      {/*problems cpu temp*/}
      {showAdvaceInfo ? (
        <td className="glo_width200px">
          {db_AllCafePCStatus?.[3]
            ?.filter((y: any) => {
              return y.cafe === x?.cafe;
            })
            ?.map((z: any, zi: number) => {
              return (
                <div key={zi}>
                  <div
                    style={{
                      backgroundColor:
                        z?.CPU_Problems_Pctg > 1 ? "darkred" : "",
                    }}
                  >
                    <Custom_ToolTip
                      c_cafe={x?.cafe}
                      c_pc_name={z?.PC_Name}
                      pcinfo2={
                        <>
                          <div>
                            {`
                    ${(
                      (z?.CPU_Problem_Found_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.CPU_Problem_TotalSample_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                          </div>
                          <div>
                            {`
               (${z?.CPU_Problem_Found_Count} /
                 ${z?.CPU_Problem_TotalSample_Count}) Samples `}
                          </div>
                        </>
                      }
                    >
                      {`🚨${z?.PC_Name},  ${z?.CPU_Problems_Pctg}% `}
                    </Custom_ToolTip>
                  </div>
                </div>
              );
            })}
        </td>
      ) : null}

      {/* problems gpu temp */}
      {showAdvaceInfo ? (
        <td className="glo_width200px">
          {db_AllCafePCStatus?.[4]
            ?.filter((y: any) => {
              return y.cafe === x?.cafe;
            })
            ?.map((z: any, zi: number) => {
              return (
                <div key={zi}>
                  <div
                    style={{
                      backgroundColor:
                        z?.GPU_Problems_Pctg > 1 ? "darkred" : "",
                    }}
                  >
                    <Custom_ToolTip
                      c_cafe={x?.cafe}
                      c_pc_name={z?.PC_Name}
                      pcinfo2={
                        <>
                          <div>
                            {`
                    ${(
                      (z?.GPU_Problem_Found_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.GPU_Problem_TotalSample_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                          </div>
                          <div>
                            {`
               (${z?.GPU_Problem_Found_Count} /
                 ${z?.GPU_Problem_TotalSample_Count}) Samples `}
                          </div>
                        </>
                      }
                    >
                      {`🚨${z?.PC_Name},  ${z?.GPU_Problems_Pctg}% `}
                    </Custom_ToolTip>
                  </div>
                </div>
              );
            })}
        </td>
      ) : null}

      {/* bottle-neck cpu usage */}
      {/* {showAdvaceInfo ? (
        <td className="glo_width200px">
          {db_AllCafePCStatus?.[5]
            ?.filter((y: any) => {
              return y.cafe === x?.cafe;
            })
            ?.map((z: any, zi: number) => {
              return (
                <div key={zi}>
                  <div
                    style={{
                      backgroundColor:
                        z?.CPU_Problems_Pctg > 1 ? "darkred" : "",
                    }}
                  >
                    <Custom_ToolTip
                      c_cafe={x?.cafe}
                      c_pc_name={z?.PC_Name}
                      pcinfo2={
                        <>
                          <div>
                            {`
                    ${(
                      (z?.CPU_Problem_Found_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.CPU_Problem_TotalSample_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                          </div>
                          <div>
                            {`
               (${z?.CPU_Problem_Found_Count} /
                 ${z?.CPU_Problem_TotalSample_Count}) Samples `}
                          </div>
                        </>
                      }
                    >
                      {`🚨${z?.PC_Name},  ${z?.CPU_Problems_Pctg}% `}
                    </Custom_ToolTip>
                  </div>
                </div>
              );
            })}
        </td>
      ) : null} */}

      {/* bottle-neck Gpu usage */}
      {/* {showAdvaceInfo ? (
        <td className="glo_width200px">
          {db_AllCafePCStatus?.[6]
            ?.filter((y: any) => {
              return y.cafe === x?.cafe;
            })
            ?.map((z: any, zi: number) => {
              return (
                <div key={zi}>
                  <div
                    style={{
                      backgroundColor:
                        z?.GPU_Problems_Pctg > 1 ? "darkred" : "",
                    }}
                  >
                    {" "}
                    <Custom_ToolTip
                      c_cafe={x?.cafe}
                      c_pc_name={z?.PC_Name}
                      pcinfo2={
                        <>
                          <div>
                            {`
                  ${(
                    (z?.GPU_Problem_Found_Count *
                      CareUScanTick_Minute.current) /
                    60
                  )?.toFixed(1)}
                  /
                  ${(
                    (z?.GPU_Problem_TotalSample_Count *
                      CareUScanTick_Minute.current) /
                    60
                  )?.toFixed(1)}
                  
                  Hours`}
                          </div>
                          <div>
                            {`
             (${z?.GPU_Problem_Found_Count} /
               ${z?.GPU_Problem_TotalSample_Count}) Samples `}
                          </div>
                        </>
                      }
                    >
                      {`🚨${z?.PC_Name},  ${z?.GPU_Problems_Pctg}% `}
                    </Custom_ToolTip>
                  </div>
                </div>
              );
            })}
        </td>
      ) : null} */}

      {/* total pc per total hour */}
      {/* {showAdvaceInfo ? (
        <td className="glo_width1000px">
          <Chart_Calendar cafe={x?.cafe} />
        </td>
      ) : null} */}
    </tr>
  ));
  //----------------------------------------------- func

  const CC_HealthyBar = ({ selected_cafe }: any) => {
    // 9
    let cc_total_pc_count = db_AllCafePCStatus[9]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
        selected_cafe?.toString()?.toLowerCase()
    )[0]?.Total_PC;
    // cc_totalpc;
    console.log("cc_total_pc_count", cc_total_pc_count);

    // 0
    let cc_offday_pc_count = db_AllCafePCStatus[0]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
          selected_cafe?.toString()?.toLowerCase() && x.Day_OFF >= 2
    )?.length;
    // cc_offday_status;
    console.log("cc_offday_pc_count", cc_offday_pc_count);

    // 1
    let cc_vir_pc_count = db_AllCafePCStatus[1]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
          selected_cafe?.toString()?.toLowerCase() && x.CPU_VIR_Status_Pctg > 1
    )?.length;
    // cc_vir_status;
    console.log("cc_vir_pc_count", cc_vir_pc_count);

    // 2
    let cc_100MBLAN_pc_count = db_AllCafePCStatus[2]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
          selected_cafe?.toString()?.toLowerCase() && x.LAN_Status_Pctg > 1
    )?.length;
    // cc_100MBLAN_status;
    console.log("cc_100MBLAN_pc_count", cc_100MBLAN_pc_count);

    // 3
    let cc_cputemp_pc_count = db_AllCafePCStatus[3]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
          selected_cafe?.toString()?.toLowerCase() && x.CPU_Problems_Pctg > 1
    )?.length;
    // cc_cputemp_status;
    console.log("cc_cputemp_pc_count", cc_cputemp_pc_count);

    // 4
    let cc_gputemp_pc_count = db_AllCafePCStatus[4]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
          selected_cafe?.toString()?.toLowerCase() && x.GPU_Problems_Pctg > 1
    )?.length;
    // cc_gputemp_status;
    console.log("cc_gputemp_pc_count", cc_gputemp_pc_count);

    // 5
    let cc_cpuproblem_pc_count = db_AllCafePCStatus[5]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
          selected_cafe?.toString()?.toLowerCase() && x.CPU_Problems_Pctg > 1
    )?.length;
    // cc_cpuproblem_status;
    console.log("cc_cpuproblem_pc_count", cc_cpuproblem_pc_count);

    // 6
    let cc_gpuproblem_pc_count = db_AllCafePCStatus[6]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
          selected_cafe?.toString()?.toLowerCase() && x.GPU_Problems_Pctg > 1
    )?.length;
    // cc_gpuproblem_status;
    console.log("cc_gpuproblem_pc_count", cc_gpuproblem_pc_count);

    // 7
    let cc__vir_status_now_pc_count = db_AllCafePCStatus[7]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
        selected_cafe?.toString()?.toLowerCase()
    )?.length;
    // cc__vir_status_now;
    console.log("cc__vir_status_now_pc_count", cc__vir_status_now_pc_count);

    // 8
    let cc__100MBLAN_status_now_pc_count = db_AllCafePCStatus[8]?.filter(
      (x: any) =>
        x.cafe?.toString()?.toLowerCase() ===
        selected_cafe?.toString()?.toLowerCase()
    )?.length;
    // cc__100MBLAN_status_now;
    console.log(
      "cc__100MBLAN_status_now_pc_count",
      cc__100MBLAN_status_now_pc_count
    );

    let result =
      100 -
      (((cc_offday_pc_count / cc_total_pc_count) * 100) / 7 +
        ((cc_vir_pc_count / cc_total_pc_count) * 100) / 7 +
        ((cc_100MBLAN_pc_count / cc_total_pc_count) * 100) / 7 +
        ((cc_cputemp_pc_count / cc_total_pc_count) * 100) / 7 +
        ((cc_gputemp_pc_count / cc_total_pc_count) * 100) / 7 +
        // ((cc_cpuproblem_pc_count / cc_total_pc_count) * 100) / 9 +
        // ((cc_gpuproblem_pc_count / cc_total_pc_count) * 100) / 9 +
        ((cc__vir_status_now_pc_count / cc_total_pc_count) * 100) / 7 +
        ((cc__100MBLAN_status_now_pc_count / cc_total_pc_count) * 100) / 7) *
        10;

    return (
      <>
        {`${selected_cafe?.toUpperCase()}  ( 🩺 ${result?.toFixed(
          3
        )}% Health )`}{" "}
      </>
    );
  };

  // ----------------------------------------------- useEffect
  useEffect(() => {
    console.log("param_cc", param_cc);
    setDb_AllCafeStatus([]);

    get_DB_AllCafe_Status("", "", "current");
    get_DB_AllCafePC_Status();
  }, []);

  return (
    <div className="glo_bg">
      <div style={{ color: "gold" }}>
        <h1>
          <CC_HealthyBar selected_cafe={param_cc} />
        </h1>
        <div style={{ color: "gray", fontSize: "10px" }}>
          (Testing Fomular : 100% - (total offday pc % + total viroff pc % +
          total 100mblan pc% + total cpu temp pc% + total gpu pc% + cpu vir now
          pc% + 100mb lan now pc%)/7*10)
        </div>
      </div>
      <Table className="table_pcstatus" verticalSpacing="xs" fontSize="xs">
        <thead>
          <tr>
            <th>Cafe</th>
            <th>
              <div>LAN 100M - NOW!</div>
            </th>
            <th>
              <div>LAN 100M % </div>
            </th>
            <th>VT Off- NOW!</th>
            <th>VT Off % </th>
            <th>Pc Offline List</th>
            <th>Pc Start Up Time</th>
            {/* <th>
              <div>GNOFOT</div>
              <div>(◀︎ 15min)</div>
            </th> */}
            {showAdvaceInfo ? (
              <th>
                <div>⭐CPU</div>
                <div>(▶︎90c & ◀︎50%)</div>
                <Tooltip
                  position="right-start"
                  withArrow
                  arrowSize={20}
                  label="ie. Showing the probability that CPU have Temperature issues, base on the total samples collected."
                >
                  <div style={{ color: "gray" }}>explaination</div>
                </Tooltip>
              </th>
            ) : null}
            {showAdvaceInfo ? (
              <th>
                <div>⭐GPU</div>
                <div>(▶︎85c & ◀︎50%)</div>
              </th>
            ) : null}
            {/* {showAdvaceInfo ? <th>⭐CPU BottleNeck (▶︎90c & ▶︎90%)</th> : null} */}
            {/* {showAdvaceInfo ? <th>⭐GPU BottleNeck (▶︎90c & ▶︎90%)</th> : null} */}
            {/* {showAdvaceInfo ? (
                <th>⭐Total PC (ALL/Gaming/Online) Hour / pc</th>
              ) : null} */}
          </tr>
        </thead>
        <tbody>{tablerows}</tbody>
      </Table>
    </div>
  );
};

const Custom_ToolTip = ({ children, c_cafe, c_pc_name, pcinfo2 }: any) => {
  const [db_Data, setDb_Data] = useState<any>([]);
  const get_DB_Pcinfo__bycafepc = (cafe: any, PC_Name: any) => {
    axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__pcinfo__bycafeandpcname`,
        {
          cafe,
          PC_Name,
        }
      )
      .then((res) => {
        // console.log("get_DB_Pcinfo__bycafepc", res.data?.[0]);
        setDb_Data(res.data?.[0]);
      });
  };

  useEffect(() => {
    get_DB_Pcinfo__bycafepc(c_cafe, c_pc_name);
  }, []);

  return (
    <Tooltip
      color="darkcyan"
      position="right-start"
      withArrow
      arrowSize={20}
      label={
        <div>
          <div>{`⏱️ last status uptate ${db_Data?.timepast} minute ago`} </div>
          <div>{db_Data?.hide_pc !== null ? `🙈 this PC is Hideden` : ``}</div>
          <br />
          <div>{`${db_Data?.cafe} ${db_Data?.PC_Name}`} </div>
          <hr />
          <div>{`CPU  : ${db_Data?.CPU_Name}`}</div>
          <div>{`GPU  : ${db_Data?.GPU_Name}`}</div>
          <div>{`VRAM : ${
            parseInt(db_Data?.GPU_AdapterRAM?.replace("|")) / 1024
          } GB`}</div>
          <div>{`RAM  : ${
            parseInt(db_Data?.RAM_Capacity?.split(",")?.[0]) / 1024
          } GB (  ${db_Data?.RAM_Capacity?.split(",")
            ?.filter((x: any) => x != "")
            ?.slice(1)
            ?.map((x: any) => `${x / 1024} GB`)}  )`}</div>
          <hr />
          {pcinfo2}
        </div>
      }

      // openDelay={500}
      // closeDelay={500}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default Page_CCStatus;
