import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

//ref fb login: https://www.youtube.com/watch?v=kEfe9u5F_L0&t=33s
//ref email login & logout: https://www.youtube.com/watch?v=9bXhf_TELP4

// TODO: Replace the following with your app's Firebase project configuration

const firebaseConfig = {
  apiKey: "AIzaSyAavgzxzL-IBn6t_-F1JgkmYVlIFNs4FTw",
  authDomain: "eclipse-crm-c640f.firebaseapp.com",
  projectId: "eclipse-crm-c640f",
  storageBucket: "eclipse-crm-c640f.appspot.com",
  messagingSenderId: "349693212056",
  appId: "1:349693212056:web:f87b2eed5e412290a567ed",
  measurementId: "G-371MH8ZM4J",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default getAuth(app);
