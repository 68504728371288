import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import axios from "axios";

// import useFirebase from "../Hooks/useFirebase";
import moment from "moment";

import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";
import { Button, Modal, Checkbox, Table, Radio, Tabs } from "@mantine/core";

import CTX from "../Hooks/useContext";

// const REACT_APP_ECLIPSE_API = import.meta.env.VITE_REACT_APP_ECLIPSE_API;
const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

//id, u_email, u_displayname, u_pic_url, u_auth, create_dt, create_by, edit_dt, edit_by, archive, archive_by, archive_dt, cafes_own
type UserType = {
  id: string;
  u_email: string;
  u_displayname: string;
  u_pic_url: string;
  u_auth: string;
  u_custom_name: string;
  create_dt: string;
  create_by: string;
  edit_dt: string;
  edit_by: string;
  archive: string;
  archive_by: string;
  archive_dt: string;
  cafes_own: string;
};

export const COM_Users_Setting = () => {
  const ctx = useContext(CTX);

  const [DB_Users, setDB_Users] = useState<any>([]);
  const get_DB_Users = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_ecs_users__authUser__all`, {})
      .then((res) => {
        // for Auth 4 & 5
        if (ctx.current?.DB_Users?.u_auth >= "4") {
          return setDB_Users(res.data);
        } else {
          // for Auth 1,2,3
          // my list
          let my_List = ctx.current?.DB_Users?.cafes_own
            .split(",")
            ?.map((x: any) => x.trim())
            ?.filter((x: any) => {
              if (x.trim() !== "") return x;
            });

          let result_SammItems = res.data?.filter((x: any, xi: any) => {
            if (x.cafes_own === null) return;
            let cafes_own_byPerson = x?.cafes_own
              ?.split(",")
              ?.map((x: any) => x.trim())
              ?.filter((x: any) => {
                if (x.trim() !== "") return x;
              });
            // cafes_own_byPerson;
            // xi;

            // my_List;
            // cafes_own_byPerson[0]?.length;
            let result = cafes_own_byPerson?.filter((y: any, yi: any) => {
              // y;
              return my_List?.includes(y);
            });

            if (result.length === 0) return;
            // result;
            return x;
          });

          setDB_Users(result_SammItems);
        }
        console.log("ctx u_auth", ctx.current?.DB_Users?.u_auth);
        console.log("ctx", ctx.current?.DB_Users?.cafes_own);
        console.log("get_DB_Users", res.data);
      });
  };

  const set_DB_Users_byid = (
    id: string,
    u_auth: string,
    cafes_own: string,
    edit_by: string
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_eclipse_ecs_users__authUser__byid`, {
        id,
        u_auth,
        cafes_own,
        edit_by,
      })
      .then((res) => {
        setDB_Users(res.data);
        // console.log("get_DB_Users", res.data);
        get_DB_Users();
        if (res.data.affectedRows > 0) {
          alert("Updated successfully");
        }
      });
  };

  const set_Archive_User__byid = (id: string, archive_by: string) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_ecs_users__archiveuser__byid`,
        {
          id,
          archive_by,
        }
      )
      .then((res) => {
        if (res.data.affectedRows > 0) {
          get_DB_Users();
          alert("Archive successfully");
        }
      });
  };

  //--------------------------------- Database
  const set_DB_eclipse_ecs_users_CustomNickName = (
    customNickName: string,
    u_email: string
  ) => {
    console.log("set_DB_eclipse_ecs_users_CustomNickName", customNickName);
    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_golbal_eclipse__eclipse_ecs_users`, {
        customNickName,
        u_email,
      })
      .then((res) => {
        console.log("set_DB_eclipse_ecs_users_CustomNickName", res.data);
        if (res.data.affectedRows > 0) {
          alert("Custom NickName Updated !");
          window.location.reload();
        }
      });
  };

  //id, u_email, u_displayname, u_pic_url, u_auth, create_dt, create_by, edit_dt, edit_by, archive, archive_by, archive_dt, cafes_own
  const columns = useMemo<MRT_ColumnDef<UserType>[]>(
    () => [
      {
        // accessorKey: "id",
        header: "Action",
        minSize: 200,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <>
              <Button
                disabled={
                  ctx.current?.DB_Users.u_auth > row.original.u_auth ||
                  ctx.current?.DB_Users.u_auth == 5
                    ? false
                    : true
                }
                onClick={() => {
                  setModalOpened(true);
                  setSelectedUser(row.original);
                  setSelected_UserAuthLevel(row.original.u_auth);
                  setSelected_Cb_CafeNameList(row.original.cafes_own);

                  // console.log("DB_Users", row.original);
                  // console.log("renderedCellValue", row.original.id);
                }}
              >
                {/* {`${ctx.current?.DB_Users.u_auth}>${row.original.u_auth} = ${
                  ctx.current?.DB_Users.u_auth > row.original.u_auth ? "y" : "n"
                } . `} */}
                EDIT ♖♛♔
              </Button>
            </>

            <>
              <Button
                disabled={ctx.current?.DB_Users.u_auth >= 4 ? false : true}
                variant="subtle"
                color="red"
                onClick={() => {
                  if (window.confirm("Are you sure to delete this user ?")) {
                    set_Archive_User__byid(
                      row.original.id,
                      row.original.edit_by
                    );
                    // console.log("row.original", row.original);
                    // alert("Deleted !"); //
                  }
                }}
              >
                DEL ♛
              </Button>
            </>
          </>
        ),
      },
      {
        accessorKey: "id",
        header: "ID",
        maxSize: 30,
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      // {
      //   accessorKey: "u_email",
      //   header: "Email",
      //   maxSize: 50,
      //   Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      // },
      {
        accessorKey: "u_email",
        header: "Email",
        minSize: 350,
        Cell: ({ renderedCellValue, row }) => (
          <div className="glo_flex_row">
            <img
              src={row.original.u_pic_url}
              height="50rem"
              style={{ borderRadius: "60rem", marginRight: "1rem" }}
            />
            <span>{`${row.original.u_custom_name}(${renderedCellValue})`}</span>
          </div>
        ),
      },

      // {
      //   accessorKey: "u_pic_url",
      //   header: "PIC",
      //   maxSize: 50,
      //   Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      // },
      {
        accessorKey: "u_auth",
        header: "Auth Level",
        minSize: 50,
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row.original.u_auth == "0" ? "0 Not Allow" : ""}
            {row.original.u_auth == "1" ? "1♞ Staff" : ""}
            {row.original.u_auth == "2" ? "2♝ Supervisor" : ""}
            {row.original.u_auth == "3" ? "3♖ Boss" : ""}
            {row.original.u_auth == "4" ? "4♛ Eclipse Staff" : ""}
            {row.original.u_auth == "5" ? "5♔ Admin" : ""}
          </span>
        ),
      },
      {
        accessorKey: "cafes_own",
        header: "Cafes Owned",
        minSize: 150,
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      {
        accessorKey: "create_dt",
        header: "Create At",
        minSize: 50,
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      {
        accessorKey: "create_by",
        header: "Create By",
        minSize: 50,
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      {
        accessorKey: "edit_dt",
        header: "Edit At",
        minSize: 50,
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      {
        accessorKey: "edit_by",
        header: "Edit By",
        minSize: 50,
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
    ],
    []
  );

  //----------------- component func
  const formatDate = (x: any) => {
    let result = "";
    if (x === null || x === undefined) {
      result = "NA";
    } else if (x.includes("2000")) {
      result = "Downloading...";
    } else {
      result = `${x} , ${moment(x?.toString()).fromNow()}`;
    }

    return result;
  };

  //----------------- glo var
  const [selected_Cb_CafeNameList, setSelected_Cb_CafeNameList] =
    useState<string>("");

  type selected_Cb_CafeNameList_Type = { cafe: string; owned: boolean };
  const CafeNameList = useRef<selected_Cb_CafeNameList_Type[]>([
    { cafe: "Gift BBA", owned: false },
    { cafe: "Gift BBK", owned: false },

    { cafe: "Gift BukitAngsana", owned: false },
    { cafe: "Gift Serdang", owned: false },
    { cafe: "Gift CherasPerdana", owned: false },
    { cafe: "Gift EquinePark", owned: false },
    { cafe: "Gift Evesuite", owned: false },
    { cafe: "Gift Impian", owned: false },
    { cafe: "Gift KajangIndah", owned: false },
    { cafe: "Gift Kepong Baru", owned: false },
    { cafe: "Gift KotaDamansara", owned: false },
    { cafe: "Gift LembahMaju", owned: false },
    { cafe: "Gift Manjalara", owned: false },
    { cafe: "Gift Nirwana", owned: false },
    { cafe: "Gift OUG", owned: false },
    { cafe: "Gift PandanIndah", owned: false },
    { cafe: "Gift SGBuloh", owned: true },
    { cafe: "Gift SriManja", owned: false },
    { cafe: "Gift SungaiLong", owned: false },
    { cafe: "Gift Suntek", owned: false },

    { cafe: "Redsea PS", owned: false },
    { cafe: "Redsea PV12", owned: false },
    { cafe: "Redsea TBR", owned: false },
    { cafe: "Redsea Wangsa Maju", owned: false },
  ]);

  const [selected_UserAuthLevel, setSelected_UserAuthLevel] = useState("");

  const [modalOpened, setModalOpened] = useState(false);

  type selectedUserType = {
    id: string;
    u_email: string;
    u_displayname: string;
    u_pic_url: string;
    u_auth: string;
    u_custom_name: string;
    create_dt: string;
    create_by: string;
    edit_dt: string;
    edit_by: string;
    archive: string;
    archive_by: string;
    archive_dt: string;
    cafes_own: string;
  };

  const [selectedUser, setSelectedUser] = useState<selectedUserType>({
    id: "",
    u_email: "",
    u_displayname: "",
    u_pic_url: "",
    u_auth: "",
    u_custom_name: "",
    create_dt: "",
    create_by: "",
    edit_dt: "",
    edit_by: "",
    archive: "",
    archive_by: "",
    archive_dt: "",
    cafes_own: "",
  });

  const user_TableInfo = (
    <tr key={selectedUser.id}>
      <td>
        <img
          src={selectedUser.u_pic_url}
          height="30rem"
          style={{ borderRadius: "30rem" }}
        />
      </td>
      <td>{selectedUser.id}</td>
      <td>{selectedUser.u_displayname}</td>
      <td>{selectedUser.u_custom_name}</td>
      <td>{selectedUser.u_email}</td>
      <td>{selectedUser.cafes_own}</td>
      <td>{selectedUser.u_auth}</td>
    </tr>
  );

  useEffect(() => {
    get_DB_Users();
  }, []);

  return (
    <div>
      <Modal
        fullScreen
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={<h1>User Setting</h1>}
      >
        <h2>User Info</h2>
        <Table highlightOnHover>
          <thead>
            <tr>
              <th />
              <th>ID</th>
              <th>Name</th>
              <th>Custom Nick Name</th>
              <th>Email</th>
              <th>Cafe Owned</th>
              <th>Auth Level</th>
            </tr>
          </thead>

          <tbody>{user_TableInfo}</tbody>
        </Table>
        <Button
          color="red"
          onClick={() => {
            if (
              window.confirm("Are you sure to reset custom nick name?") === true
            ) {
              set_DB_eclipse_ecs_users_CustomNickName(
                "reseted",
                selectedUser.u_email
              );
              console.log("Reset Custom Nick Name", selectedUser.u_email);
            }
          }}
        >
          Reset Custom Nick Name
        </Button>
        <br />
        <br />
        <br />
        <br />

        <h2>EDIT : User Auth Level </h2>
        <Radio.Group
          size="lg"
          value={selected_UserAuthLevel}
          onChange={setSelected_UserAuthLevel}
          name=""
          label=""
          description=""
          withAsterisk
        >
          {/* <Radio value="0" label="0 = Some Body - no allowed enter" /> */}
          {/* <Radio value="1" label="1 = Staff - basic info viewer" /> */}
          {/* <Radio color="red" value="2" label="2 = Supervisor - view and edit" /> */}
          <Radio
            value="0"
            color="dark"
            label={
              <div style={{ color: "" }}>LV 0 = Some Body - No Access</div>
            }
          />
          <Radio
            value="1"
            color="green"
            label={<div style={{ color: "blue" }}>LV 1 = ♞ Staff</div>}
          />
          <Radio
            disabled={ctx.current?.DB_Users.u_auth > 2 ? false : true}
            value="2"
            color="red"
            label={<div style={{ color: "green" }}>LV 2 = ♝ Supervisor</div>}
          />

          <Radio
            disabled={ctx.current?.DB_Users.u_auth > 3 ? false : true}
            value="3"
            color="red"
            label={<div style={{ color: "gold" }}>LV 3 = ♖ Boss</div>}
          />
          <Radio
            disabled={ctx.current?.DB_Users.u_auth > 4 ? false : true}
            value="4"
            color="red"
            label={
              <div style={{ color: "orange" }}>LV 4 = ♛ Eclipse Staff</div>
            }
          />
          <Radio
            disabled={ctx.current?.DB_Users.u_auth >= 5 ? false : true}
            value="5"
            color="red"
            label={<div style={{ color: "red" }}>Lv 5 = ♔ Admin</div>}
          />
        </Radio.Group>

        <br />
        <br />
        <br />

        <h2>EDIT : Cafe Owned</h2>
        <Checkbox.Group
          size="lg"
          onChange={(x) => {
            setSelected_Cb_CafeNameList(x + " , ");
          }}
          defaultValue={CafeNameList.current
            .filter((x) => {
              // console.log("selectedUser.cafes_own", selectedUser.cafes_own);
              // console.log("x.cafe", x.cafe);
              // console.log("result", selectedUser.cafes_own.includes(x.cafe));
              if (selectedUser?.cafes_own?.includes(x.cafe)) {
                return x.cafe;
              }
            })
            .map((x) => {
              return x.cafe;
            })}
          label=""
          description=""
          withAsterisk
        >
          {CafeNameList.current.map((x, i) => {
            return (
              <Checkbox key={i} value={x.cafe} label={`${i + 1}. ${x.cafe}`} />
            );
          })}
        </Checkbox.Group>
        <div className="glo_system_ui">{selected_Cb_CafeNameList}</div>

        <hr />
        <div className="glo_flex_column">
          <Button
            size="xl"
            color="red"
            onClick={() => {
              if (window.confirm("Are you sure to update user info?")) {
                // console.log("ok");
                set_DB_Users_byid(
                  selectedUser.id,
                  selected_UserAuthLevel,
                  selected_Cb_CafeNameList,
                  ctx.current.firebase_UserInfo.email
                );

                setModalOpened(false);
                window.location.reload();
              }
            }}
          >
            UPDATE USER INFO
          </Button>
        </div>
      </Modal>

      <p>
        <div>Lv 0 = Not Allow</div>
        <div>Lv 1 = ♞ Staff</div>
        <div>Lv 2 = ♝ Supervisor</div>
        <div>Lv 3 = ♖ Boss</div>
        <div>Lv 4 = ♛ Eclipse Staff</div>
        <div>Lv 5 = ♔ Admin</div>
        <br />
        <b>
          {`My Cafes Owned (${
            ctx.current?.DB_Users?.cafes_own
              ?.split(",")
              ?.map((x: any) => x.trim())
              ?.filter((x: any) => {
                if (x.trim() !== "") return x;
              })?.length
          } cafes)`}{" "}
        </b>
        <br />
        <b>{ctx.current?.DB_Users?.cafes_own}</b>
      </p>
      <br />
      <MantineReactTable
        columns={columns}
        data={DB_Users}
        enableGrouping
        initialState={{
          expanded: true, //expand all groups by default
          // grouping: ["game_category"], //an array of columns to group by by default (can be multiple)
          pagination: { pageIndex: 0, pageSize: 100 },
          // sorting: [{ id: "id", desc: false }], //sort by state by default
          density: "xs",
          // showColumnFilters: true,
        }}
        enableColumnResizing
        columnResizeMode="onChange" //default
        mantinePaginationProps={
          {
            // rowsPerPageOptions: ["500", "1000"],
          }
        }
      />
    </div>
  );
};

export default COM_Users_Setting;
