import { useEffect, useRef, useState, createContext, useContext } from "react";
// ------------------------------------------------- router
import {
  HashRouter,
  BrowserRouter,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import ProtectedRoutes from "./Routers/ProtectedRoutes";

import Page_Login from "./Page_Login";
import Page_Home from "./Page_Home";
import Page_Setting from "./Page_Setting";

import useFirebase from "./Hooks/useFirebase";
import useRoleAuth from "./Hooks/useRoleAuth";

import CTX from "./Hooks/useContext";
import COM_Nav_Global from "./Components/COM_Nav_Global";

import "./Css/global.css";
import "./Css/table.css";
import Page_CCStatus from "./Page_CCStatus";

function App() {
  // console.log("--------------------------- App");
  const ctx = useRef({
    authorization: false,
    authentication: false,
  });

  // // ---------------------------------------------------------  Firebase login
  // const [LoginGUI, LogoutGUI, firebase_UserInfo] = useFirebase("App");
  // // ---------------------------------------------------------  DB user Auth

  // const { DB_Users } = useRoleAuth({
  //   user_email: firebase_UserInfo?.email,
  // });

  // console.log(
  //   "App - firebase_UserInfo",
  //   firebase_UserInfo?.email !== undefined ? true : false
  // );

  return (
    <>
      <CTX.Provider value={ctx}>
        <HashRouter>
          <Routes>
            <Route
              element={
                <ProtectedRoutes
                // P_isAuth={ctx.current.authentication === true}
                />
              }
            >
              <Route path="/" element={<Page_Home />} />

              <Route path="/home" element={<Page_Home />} />
              <Route path="/settings" element={<Page_Setting />} />
            </Route>
            {/* <ProtectedRoute exact path="/main" component={() => <Page_Main />} /> */}
            <Route path="/ccstatus" element={<Page_CCStatus />} />
          </Routes>
        </HashRouter>
      </CTX.Provider>
    </>
  );
}

export default App;
