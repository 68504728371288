import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import axios from "axios";

import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";
import { Button, Modal, Checkbox, Table, Radio, Tabs } from "@mantine/core";

import CTX from "../Hooks/useContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

type CafesType = {
  cid: string;
  cname: string;
  c_ip: string;
  c_mac: string;

  update_datetime: string;
  co_ip: string;
  co_mac: string;

  edit_by: string;
  edit_dt: string;
};

export const COM_Cafes_Setting = () => {
  const ctx = useContext(CTX);

  const [DB_Users, setDB_Users] = useState<any>([]);
  const get_LocalAuth = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_golbal_eclipse__locseridfy`, {})
      .then((res) => {
        setDB_Users(res.data);
        // console.log("get_golbal_eclipse__locseridfy", res.data);
      });
  };

  const set_LocalAuth = (
    c_ip: string,
    c_mac: string,
    edit_by: string,
    cid: string
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_golbal_eclipse__locseridfy__byid`, {
        c_ip,
        c_mac,
        edit_by,
        cid,
      })
      .then((res) => {
        console.log("set_golbal_eclipse__locseridfy__byid", res.data);
        if (res.data.changedRows !== 0) {
          get_LocalAuth();
          alert("Update Successfully !");
        }
      });
  };

  useEffect(() => {
    get_LocalAuth();
  }, []);

  const columns = useMemo<MRT_ColumnDef<CafesType>[]>(
    () => [
      {
        accessorKey: "cid",
        header: "ID",
        maxSize: 1,
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      {
        accessorKey: "cname",
        header: "Cafe",
        maxSize: 1,
        Cell: ({ renderedCellValue, row }) => (
          <div className="glo_flex_row">
            <span>{`${row.original.cname}`}</span>
          </div>
        ),
      },
      {
        // accessorKey: "cname",
        header: "IP MAC",
        maxSize: 1,
        Cell: ({ renderedCellValue, row }) => (
          <div
            style={{
              color:
                row.original.co_ip !== row.original.c_ip ||
                row.original.co_mac !== row.original.c_mac
                  ? "red"
                  : "",
            }}
          >
            <span>{`${row.original.c_ip}`}</span>
            <br />
            <span>{`${row.original.c_mac}`}</span>
          </div>
        ),
      },
      {
        // accessorKey: "id",
        header: "Action",
        maxSize: 1,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <>
              {row.original.co_ip !== row.original.c_ip ||
              row.original.co_mac !== row.original.c_mac ? (
                <Button
                  variant="filled"
                  color="red"
                  onClick={() => {
                    if (window.confirm("sure?")) {
                      set_LocalAuth(
                        row.original.co_ip,
                        row.original.co_mac,
                        ctx.current?.DB_Users?.u_email,
                        row.original.cid
                      );
                    }
                    // console.log("DB_Users", row.original);
                    // console.log("renderedCellValue", row.original.id);
                  }}
                >
                  {/* {`${ctx.current?.DB_Users.u_auth}>${row.original.u_auth} = ${
                  ctx.current?.DB_Users.u_auth > row.original.u_auth ? "y" : "n"
                } . `} */}
                  ⬅️ Assign
                </Button>
              ) : (
                ""
              )}
            </>
          </>
        ),
      },
      {
        accessorKey: "update_datetime",
        header: "Last Update",
        maxSize: 1,
        Cell: ({ renderedCellValue }) => (
          <>
            <span>{renderedCellValue?.toString()?.split(" ")[0]}</span>
            <br />
            <span>{renderedCellValue?.toString()?.split(" ")[1]}</span>
          </>
        ),
      },
      {
        // accessorKey: "cname",
        header: "Detected IP MAC",
        maxSize: 1,
        Cell: ({ renderedCellValue, row }) => (
          <div
            style={{
              color:
                row.original.co_ip !== row.original.c_ip ||
                row.original.co_mac !== row.original.c_mac
                  ? "red"
                  : "",
            }}
          >
            <span>{`${row.original.co_ip}`}</span>
            <br />
            <span>{`${row.original.co_mac}`}</span>
          </div>
        ),
      },

      {
        // accessorKey: "cname",
        header: "Update By",
        maxSize: 1,
        Cell: ({ renderedCellValue, row }) => (
          <div className="">
            <span>{`${row.original.edit_by}`}</span>
            <br />
            <span>{`${row.original.edit_dt}`}</span>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Cafe - Local Server Auth Control Panel </h2>
      <Button
        variant="outline"
        color="lime"
        size="lg"
        onClick={() => {
          get_LocalAuth();
        }}
      >
        Reload Table Data
      </Button>
      <p>
        CareU Local Server IP and MAC must be same as below table, else it will
        be blocked.
      </p>
      <p style={{ color: "red" }}>
        Before Assign the Lastes IP and MAC, please make sure IP & MAC are
        legit.
      </p>
      <MantineReactTable
        columns={columns}
        data={DB_Users}
        enableGrouping
        initialState={{
          expanded: true, //expand all groups by default
          // grouping: ["game_category"], //an array of columns to group by by default (can be multiple)
          pagination: { pageIndex: 0, pageSize: 100 },
          // sorting: [{ id: "id", desc: false }], //sort by state by default
          density: "xs",
          // showColumnFilters: true,
        }}
        enableColumnResizing
        columnResizeMode="onChange" //default
        mantinePaginationProps={
          {
            // rowsPerPageOptions: ["500", "1000"],
          }
        }
      />
    </>
  );
};

export default COM_Cafes_Setting;
