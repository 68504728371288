import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
// import Page_GameStatusLog from "./Components/COM_GameUpdateLog";
import COM_GameUpdateStatus from "./Components/COM_GameUpdateStatus";
import COM_GameNewGameStatus from "./Components/COM_GameNewGameStatus";
import COM_GameRequestLog from "./Components/COM_GameRequestLog";

import axios from "axios";

// import useFirebase from "./Hooks/useFirebase";
// import useRoleAuth from "./Hooks/useRoleAuth";

import CTX from "./Hooks/useContext";

import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, TextInput } from "@mantine/core";
import COM_Nav_Global from "./Components/COM_Nav_Global";

// const REACT_APP_ECLIPSE_API = env.VITE_REACT_APP_ECLIPSE_API;
const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export const Page_Home = () => {
  const ctx = useContext(CTX);

  // console.log("--------------------------- Page_Home , ctx", ctx.current);

  // // ---------------------------------------------------------  Firebase login
  // const [LoginGUI, LogoutGUI, firebase_UserInfo] = useFirebase("Page_Home");

  // // ---------------------------------------------------------  DB user Auth
  // const { DB_Users } = useRoleAuth({
  //   user_email: firebase_UserInfo?.email,
  // });
  // useEffect(() => {
  // console.log("HOME - firebase_UserInfo?.email", firebase_UserInfo?.email);
  // console.log("HOME - DB_Users", DB_Users);
  // console.log("HOME - DB_Users.u_auth", DB_Users?.u_auth);
  // }, [firebase_UserInfo?.email, DB_Users]);

  //--------------------------------- Database
  const set_DB_eclipse_ecs_users_CustomNickName = (
    customNickName: string,
    u_email: string
  ) => {
    console.log("set_DB_eclipse_ecs_users_CustomNickName", customNickName);
    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_golbal_eclipse__eclipse_ecs_users`, {
        customNickName,
        u_email,
      })
      .then((res) => {
        console.log("set_DB_eclipse_ecs_users_CustomNickName", res.data);
        if (res.data.affectedRows > 0) {
          alert("Custom NickName Updated !");
          window.location.reload();
        }
      });
  };

  //--------------------------------- global variable
  const [SelectedTab, setSelectedTab] = useState<number>(0);
  const [selectedCafeName, setselectedCafeName] = useState<string[]>([]);
  const [Model_Opened, { open: Modal_Open, close: Modal_Close }] =
    useDisclosure(false);
  const [customNickName, setCustomNickName] = useState<string>("");

  const CafeNameList = [
    "Gift BBA",
    "Gift BBK",

    "Gift BukitAngsana",
    "Gift Serdang",
    "Gift CherasPerdana",
    "Gift EquinePark",
    "Gift Evesuite",
    "Gift Impian",
    "Gift KajangIndah",
    "Gift Kepong Baru",
    "Gift KotaDamansara",
    "Gift LembahMaju",
    "Gift Manjalara",
    "Gift Nirwana",
    "Gift OUG",
    "Gift PandanIndah",
    "Gift SGBuloh",
    "Gift SriManja",
    "Gift SungaiLong",
    "Gift Suntek",

    "Redsea PS",
    "Redsea PV12",
    "Redsea TBR",
    "Redsea Wangsa Maju",
  ];

  const selectedCafeName_Ref = useRef([]);

  useEffect(() => {
    // console.log("ctx", ctx.current?.DB_Users.cafes_own?.split(","));
    selectedCafeName_Ref.current = ctx.current?.DB_Users.cafes_own?.split(",");
    // console.log("selectedCafeName_Ref.current", selectedCafeName_Ref.current);
    setSelectedTab(1);

    // ---------------------- ask for add custom nickname

    if (
      ctx.current?.DB_Users?.u_custom_name === null ||
      ctx.current?.DB_Users?.u_custom_name === "reseted"
    ) {
      Modal_Open();

      // console.log("ctx.current?.DB_Users?.u_custom_name", ctx.current);
    }
  }, []);

  return (
    <div className="glo_mf_body">
      <Modal
        opened={Model_Opened}
        onClose={Modal_Close}
        title=""
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <h1>What people call you in company ?</h1>
        <TextInput
          placeholder="Your Custom Nick Name"
          label="Custom Nick Name"
          withAsterisk
          value={customNickName}
          onChange={(event) => setCustomNickName(event.currentTarget.value)}
        />

        <br />
        <br />
        <Button
          size={"xl"}
          onClick={() => {
            if (
              window.confirm(
                "Are you sure to update your custom nickname ?"
              ) === true
            ) {
              set_DB_eclipse_ecs_users_CustomNickName(
                customNickName,
                ctx.current?.firebase_UserInfo?.email
              );
            }
          }}
        >
          Update My Custom Nick Name
        </Button>
      </Modal>
      {/* <Button onClick={Modal_Open}>Open modal</Button> */}
      <COM_Nav_Global />

      {/* --------------- body */}
      <h1>ECS HOME </h1>

      <br />
      <b>My Cafes</b>
      <div className="glo_flex_row">
        {ctx.current?.DB_Users.cafes_own
          ?.split(",")
          .slice(0, -1)
          .map((x: string, i: number) => {
            return (
              <div key={i}>{`${i + 1}. ${x} `}</div>
              // <Button
              //   size="xl"
              //   key={i}
              //   color={selectedCafeName.length !== 0 ? "grape" : "gray"}
              //   onClick={
              //     () => console.log("first")
              //     // setselectedCafeName(x)
              //   }
              // >
              //   {`${i + 1}. ${x}`}
              // </Button>
            );
          })}
      </div>
      <br />
      {/* {ctx.current?.DB_Users.u_auth >= "5" ? (
        <div className="glo_system_ui">
          <div>* this pannel only show for admin(dev)</div>
          {CafeNameList.map((x: string, i: number) => {
            return (
              <Button
                key={i}
                color={selectedCafeName.length !== 0 ? "grape" : "gray"}
                size="sm"
                onClick={
                  () => console.log("first")

                  // setselectedCafeName(x)
                }
              >
                {x}
              </Button>
            );
          })}
        </div>
      ) : (
        ""
      )} */}

      <br />

      <br />
      <Button color="lime" size="md" onClick={() => setSelectedTab(1)}>
        Update Game Status
      </Button>
      <Button color="yellow" size="md" onClick={() => setSelectedTab(2)}>
        New Game Status
      </Button>
      <Button color="orange" size="md" onClick={() => setSelectedTab(3)}>
        Request Log
      </Button>
      <hr />

      {/* {"SelectedTab: " + SelectedTab} */}

      {SelectedTab === 1 ? (
        <COM_GameUpdateStatus selectedCafeName={selectedCafeName_Ref.current} />
      ) : (
        ""
      )}
      {SelectedTab === 2 ? (
        <COM_GameNewGameStatus
          selectedCafeName={selectedCafeName_Ref.current}
        />
      ) : (
        ""
      )}
      {SelectedTab === 3 ? (
        <COM_GameRequestLog selectedCafeName={selectedCafeName_Ref.current} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Page_Home;
