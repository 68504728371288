import React, { useEffect, useState, useRef, useContext } from "react";
// import Page_GameStatusLog from "./Components/COM_GameUpdateLog";
import COM_GameUpdateStatus from "./Components/COM_GameUpdateStatus 2";

// import useFirebase from "./Hooks/useFirebase";
// import useRoleAuth from "./Hooks/useRoleAuth";

import CTX from "./Hooks/useContext";

import { Button, Table, Tabs } from "@mantine/core";
import COM_Nav_Global from "./Components/COM_Nav_Global";
import COM_Users_Setting from "./Components/COM_Users_Setting";
import COM_Cafes_Setting from "./Components/COM_Cafes_Setting";

// const REACT_APP_ECLIPSE_API = env.VITE_REACT_APP_ECLIPSE_API;
const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export const Page_Setting = () => {
  const ctx = useContext(CTX);

  // console.log("--------------------------- Page_Setting , ctx", ctx.current);

  useEffect(() => {}, []);

  return (
    <>
      <COM_Nav_Global />
      <div className="glo_system_ui">
        {ctx.current?.DB_Users.u_auth >= "3" ? (
          <>
            <h1>Setting </h1>
            <Tabs
              color="green"
              radius="md"
              defaultValue="users"
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab value="users">
                  <h2>Users</h2>
                </Tabs.Tab>
                {ctx.current?.DB_Users.u_auth >= "4" ? (
                  <Tabs.Tab value="cafes">
                    <h2>Cafe</h2>
                  </Tabs.Tab>
                ) : (
                  ""
                )}
              </Tabs.List>
              <Tabs.Panel value="users" pt="xs">
                <>
                  {/* <div className="glo_system_ui">
                {ctx.current?.DB_Users.u_auth >= "5" ? "" : ""}
              </div>
              <br /> */}

                  <COM_Users_Setting />
                </>
              </Tabs.Panel>
              {ctx.current?.DB_Users.u_auth >= "4" ? (
                <Tabs.Panel value="cafes" pt="xs">
                  <div className="glo_system_ui">
                    <COM_Cafes_Setting />
                  </div>
                </Tabs.Panel>
              ) : (
                ""
              )}
            </Tabs>
          </>
        ) : (
          (window.location.href =
            window.location.hostname +
            window.location.pathname.split("/").slice(0, -1).join("/"))

          // "YOU'RE NOT ALLOWED!!"
        )}
      </div>
    </>
  );
};

export default Page_Setting;
