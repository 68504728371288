import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import axios from "axios";

import CTX from "../Hooks/useContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export const useRoleAuth = () => {
  const ctx = useContext(CTX);

  type fB_Obj_Type = {
    email: string;
    displayName: string;
    photoURL: string;
  };
  const [fB_Obj, setFB_Obj] = useState<fB_Obj_Type>({
    email: "",
    displayName: "",
    photoURL: "",
  });

  // console.log("--------------------------- useRoleAuth , ctx", ctx.current);

  // console.log("user_email 1 = " + user_email);

  const [DB_Users, setDB_Users] = useState<any>([]);
  const get_DB_Users = () => {
    // console.log("get_DB_Users", fB_Obj);

    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_ecs_users__authUser__byemail`,
        {
          u_email: fB_Obj?.email,
        }
      )
      .then((res) => {
        // console.log("### get_eclipse_ecs_users__authUser__byemail", res.data);
        // console.log(
        //   "### get_eclipse_ecs_users__authUser__byemail archive",
        //   res.data[0].archive
        // );
        // console.log(
        //   "### get_eclipse_ecs_users__authUser__byemail u_auth",
        //   res.data[0].u_auth
        // );

        // ------------------ new user
        if (res.data.length === 0) {
          // console.log("@@@ New User", res.data[0]);
          // console.log("@@@ New User user_email", fB_Obj.email);
          add_DB_Users_NewPublicAuto(
            fB_Obj.email,
            fB_Obj.displayName,
            fB_Obj.photoURL
          );
        }
        // ------------------ existing user
        if (res.data.length !== 0) {
          // console.log(
          //   "useRoleAuth | get_eclipse_ecs_users__authUser__byemail",
          //   res.data[0]
          // );
          setDB_Users(res.data[0]);

          // ctx.current.authorization = true;
          // ctx.current.databaseUserInfo = res.data[0];
        }
      });
  };

  const add_DB_Users_NewPublicAuto = async (
    u_email: string,
    u_displayname: string,
    u_pic_url: string
  ) => {
    // console.log("add_DB_Users_NewPublicAuto ");

    await axios
      .post(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_ecs_users__authUser__NewPublicAuto`,
        {
          u_email,
          u_displayname,
          u_pic_url,
        }
      )
      .then((res) => {
        // console.log("add_DB_Users_NewPublicAuto", res.data[0]);
      });
  };

  const runOnce = useRef(true);
  useEffect(() => {
    if (
      fB_Obj.email !== undefined &&
      fB_Obj.email !== null &&
      fB_Obj.email !== "" &&
      runOnce.current === true
    ) {
      // console.log("useEffect - useRoleAuth- fB_Obj", fB_Obj);
      get_DB_Users();
      runOnce.current = false;
      // console.log("useEffect - get_DB_Users");
    }
    // console.log("useEffect - fB_Obj", fB_Obj);
    // if (runOnce.current === false) return;
    // // console.log("user_email 3= " + user_email);

    // get_DB_Users();
    // if (fB_Obj?.email !== undefined) {
    //   runOnce.current = false;
    // }
  }, [fB_Obj?.email]);

  return { DB_Users, setFB_Obj };
};

export default useRoleAuth;
