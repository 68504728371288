import React, { useEffect, useState, useRef, useContext } from "react";

import CTX from "../Hooks/useContext";
import useFirebase from "../Hooks/useFirebase";

import { Button } from "@mantine/core";

export const COM_Nav_Global = () => {
  const ctx = useContext(CTX);

  // ---------------------------------------------------------  Firebase login
  const [LoginGUI, LogoutGUI, firebase_UserInfo] =
    useFirebase("COM_Nav_Global");

  // console.log("--------------------------- COM_Nav_Global , ctx", ctx.current);

  // console.log("p4 ", ctx.current.authentication && ctx.current.authorization);

  // -------------------------------------------- glo var

  return (
    <>
      {ctx.current.authentication && ctx.current.authorization ? (
        <div className="glo_nav_container">
          <img
            src="https://eclipseapi3.freemyip.com/uploads/EclipseMy_Web/Company_Logo.png"
            height="60rem"
            className="glo_hide_on_portrait"
          />
          <Button
            size="xl"
            color="yellow"
            variant="subtle"
            radius="xl"
            onClick={() => {
              window.location.href = "/#/home";
            }}
          >
            HOME
          </Button>
          {ctx.current?.DB_Users.u_auth >= "3" ? (
            <Button
              size="xl"
              color="yellow"
              variant="subtle"
              radius="xl"
              onClick={() => {
                window.location.href = "/#/settings";
              }}
            >
              SETTINGS
            </Button>
          ) : (
            ""
          )}
          <LogoutGUI />
        </div>
      ) : (
        "naaaa"
      )}
    </>
  );
};

export default COM_Nav_Global;
