import React, {
  useState,
  useEffect,
  useMemo,
  ReactNode,
  useContext,
  useRef,
} from "react";
import axios from "axios";

// import useFirebase from "../Hooks/useFirebase";
import CTX from "../Hooks/useContext";

import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";

import moment from "moment";

import { Box, Button, Menu, Text, Title } from "@mantine/core";

import { RxUpdate } from "react-icons/rx";
import { RiInstallFill } from "react-icons/ri";

// const REACT_APP_ECLIPSE_API = import.meta.env.VITE_REACT_APP_ECLIPSE_API;
const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

type TableData = {
  id: string;
  game_name: string;
  game_category: string;
  game_size: string;
  server_ready: string;
  cafe1_ready: string;
  cafe1_ready_status: string;
  Cafe1_Name: string;
  cafe2_ready: string;
  cafe2_ready_status: string;
  Cafe2_Name: string;
  cafe3_ready: string;
  cafe3_ready_status: string;
  Cafe3_Name: string;
  cafe4_ready: string;
  cafe4_ready_status: string;
  Cafe4_Name: string;
  cafe5_ready: string;
  cafe5_ready_status: string;
  Cafe5_Name: string;
  cafe6_ready: string;
  cafe6_ready_status: string;
  Cafe6_Name: string;
  cafe7_ready: string;
  cafe7_ready_status: string;
  Cafe7_Name: string;
  cafe8_ready: string;
  cafe8_ready_status: string;
  Cafe8_Name: string;
  cafe9_ready: string;
  cafe9_ready_status: string;
  Cafe9_Name: string;
  cafe10_ready: string;
  cafe10_ready_status: string;
  Cafe10_Name: string;
  cafe11_ready: string;
  cafe11_ready_status: string;
  Cafe11_Name: string;
  cafe12_ready: string;
  cafe12_ready_status: string;
  Cafe12_Name: string;
  cafe13_ready: string;
  cafe13_ready_status: string;
  Cafe13_Name: string;
  cafe14_ready: string;
  cafe14_ready_status: string;
  Cafe14_Name: string;
  cafe15_ready: string;
  cafe15_ready_status: string;
  Cafe15_Name: string;
  cafe16_ready: string;
  cafe16_ready_status: string;
  Cafe16_Name: string;
  cafe17_ready: string;
  cafe17_ready_status: string;
  Cafe17_Name: string;
  cafe18_ready: string;
  cafe18_ready_status: string;
  Cafe18_Name: string;
  cafe19_ready: string;
  cafe19_ready_status: string;
  Cafe19_Name: string;
  cafe20_ready: string;
  cafe20_ready_status: string;
  Cafe20_Name: string;
  cafe21_ready: string;
  cafe21_ready_status: string;
  Cafe21_Name: string;
  cafe22_ready: string;
  cafe22_ready_status: string;
  Cafe22_Name: string;
  cafe23_ready: string;
  cafe23_ready_status: string;
  Cafe23_Name: string;
  cafe24_ready: string;
  cafe24_ready_status: string;
  Cafe24_Name: string;
  cafe25_ready: string;
  cafe25_ready_status: string;
  Cafe25_Name: string;
  cafe26_ready: string;
  cafe26_ready_status: string;
  Cafe26_Name: string;
  cafe27_ready: string;
  cafe27_ready_status: string;
  Cafe27_Name: string;
  cafe28_ready: string;
  cafe28_ready_status: string;
  Cafe28_Name: string;
  cafe29_ready: string;
  cafe29_ready_status: string;
  Cafe29_Name: string;
  cafe30_ready: string;
  cafe30_ready_status: string;
  Cafe30_Name: string;
};

type PropsType = {
  selectedCafeName: string[];
};

export const Page_GameStatusLog = (props: PropsType) => {
  const ctx = useContext(CTX);

  // console.log("props", props);
  const selectedCafeName = props.selectedCafeName
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .slice(1);
  // const [LoginGUI, LogoutGUI, firebase_UserInfo] = useFirebase();

  useEffect(() => {
    // console.log("ctx", ctx.current.DB_Users.u_auth);
    // console.log("ctx", ctx);
  }, []);

  useEffect(() => {
    if (selectedCafeName.length === 0) return;
    get_DB_GameStatus(selectedCafeName, "S.dt_game_ready DESC");
    // console.log("useEffect - selectedCafeName", selectedCafeName);
  }, []);

  const add_RequestLog = (
    game_id: string,
    game_name: string,
    game_category: string,
    game_size: string,
    request_type: string,
    request_by: string,
    request_by2: string,
    request_cafes: string
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add_eclipse_mon_gamelist__requestlist`, {
        game_id: game_id,
        game_name: game_name,
        game_category: game_category,
        game_size: (parseFloat(game_size) / 1024).toFixed(2),
        request_type: request_type,
        request_by: request_by,
        request_by2: request_by2,
        request_cafes: "all",
      })
      .then((res) => {
        // console.log("add_RequestLog", res.data);
      });
  };

  const [DB_GameStatus, setDB_GameStatus] = useState<any>([]);
  const get_DB_GameStatus = (source: string[], orderBy: string) => {
    // console.log("source", source);
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_mon_gamelist__status`, {
        source,
        orderBy,
      })
      .then((res) => {
        setDB_GameStatus(res.data);
        // console.log("get_DB_GameStatus", res.data);
      });
  };

  const [DB_GameStatus_GameAmount, setDB_GameStatus_GameAmount] = useState<any>(
    []
  );
  const get_DB_GameStatus_GameAmount = () => {
    // console.log("source", source);
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_golbal_eclipse__eclipse_mon_gamelist__client__gameamount`,
        {}
      )
      .then((res) => {
        setDB_GameStatus_GameAmount(res.data);
        console.log("get_DB_GameStatus_GameAmount", res.data);
      });
  };

  const del_DB_GameStatus__byCafe = (source: string) => {
    // console.log("source", source);
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_golbal_eclipse__eclipse_mon_gamelist__client__del_bycafe`,
        {
          source,
        }
      )
      .then((res) => {
        console.log("del_DB_GameStatus__byCafe", res.data);
        if (res.data?.affectedRows > 0) {
          alert("Reset Successfully");
        } else {
          alert("Reset Failed! Please contact admin");
        }
      });
  };

  const set_SendTelegram_Notification = (
    noti_Type: string,
    game_Info: any,
    to_UserGroup: string,
    from_Cafes: string,
    from_User: string
  ) => {
    console.log("game_info", game_Info);
    console.log("to_UserGroup", to_UserGroup);
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_mon_gamelist__sentTelegramNotification__toGroup`,
        {
          noti_Type,
          game_Info,
          to_UserGroup,
          from_Cafes,
          from_User,
        }
      )
      .then((res) => {
        console.log("set_SendTelegram_Notification", res.data);
      });
  };

  type Custom_ColumnHeader_TYPE = {
    selectedCafe: string;
  };

  const Custom_ColumnHeader = (x: Custom_ColumnHeader_TYPE) => {
    return (
      <div style={{ overflowWrap: "break-word" }}>
        <b>{x.selectedCafe?.split(" ")[0]}</b>
        <br />
        <b>{x.selectedCafe?.split(" ")[1]}</b>
        <br />
        {/* <b>{`${
          DB_GameStatus_GameAmount?.filter(
            (y: any) => y.source === x.selectedCafe
          )[0]?.total_game
        }`}</b> */}
        <br />
        {ctx.current.DB_Users.u_auth === "5" ? (
          <Button
            color="red"
            onClick={() => {
              if (
                window.confirm(`Reset - ${x.selectedCafe} ? 
            will take 15-30min to regenerate.
            Please dont Spam, patient wait.
            
            Beside you are free do nevigate to other page, 
            Process will happending in Server`)
              ) {
                del_DB_GameStatus__byCafe(x.selectedCafe?.toString());
              }
            }}
          >
            <b>{`Reset - ${x.selectedCafe}`}</b>
          </Button>
        ) : (
          ""
        )}
      </div>
    );
  };

  const conlumns_amount = useRef(0);
  const columns = useMemo<MRT_ColumnDef<TableData>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 20,
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },
      {
        accessorKey: "game_name",
        header: "Game Name",
        size: 140,

        Cell: ({ renderedCellValue, row }) => (
          <>
            <b>{row.original.game_name}</b>
            <div>{row.original.game_category}</div>
            {isNaN(parseFloat(row.original.game_size)) === false ? (
              <div>{`📂 ${(parseFloat(row.original.game_size) / 1024).toFixed(
                2
              )} GB`}</div>
            ) : (
              "📂"
            )}
          </>
        ),
      },
      {
        accessorKey: "game_category",
        header: "Category",
        minSize: 100,

        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
      },

      {
        accessorKey: "server_ready",
        header: "IDC Ready",
        size: 140,

        Cell: ({ renderedCellValue, row }) => (
          <>
            <div className="tbl_bold_text">
              {formatDate(row.original.server_ready).split(",")[0]}
            </div>
            <div>{formatDate(row.original.server_ready).split(",")[1]}</div>

            {ctx.current?.DB_Users.u_auth >= 2 ? (
              <div
                className="glo_btn_normal"
                onClick={() => {
                  if (window.confirm("Are you sure to request update?")) {
                    add_RequestLog(
                      row.original.id,
                      row.original.game_name,
                      row.original.game_category,
                      row.original.game_size,
                      "request update",
                      ctx.current.firebase_UserInfo.email,
                      ctx.current.DB_Users?.u_custom_name,
                      selectedCafeName.toString()
                    );
                    set_SendTelegram_Notification(
                      "Request Update",
                      row.original,
                      "eclipse_system",
                      selectedCafeName.toString(),
                      `${ctx.current?.DB_Users?.u_custom_name}(${ctx.current.firebase_UserInfo.email})`
                    );
                  }
                  // console.log("Request Update", row.original);
                }}
              >
                <RxUpdate />
                Request Update ♝
              </div>
            ) : (
              <div>♝</div>
            )}
          </>
        ),
      },

      {
        accessorKey: "cafe1_ready_status",
        header: selectedCafeName[0],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[0].toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe1_ready}
              cafe={row.original?.Cafe1_Name}
              game_id={row.original?.id}
              game_name={row.original?.game_name}
              game_category={row.original?.game_category}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe2_ready_status",
        header: selectedCafeName[1],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[1]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe2_ready}
              cafe={row.original?.Cafe2_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe3_ready_status",
        header: selectedCafeName[2],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[2]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe3_ready}
              cafe={row.original?.Cafe3_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe4_ready_status",
        header: selectedCafeName[3],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[3]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe4_ready}
              cafe={row.original?.Cafe4_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe5_ready_status",
        header: selectedCafeName[4],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[4]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe5_ready}
              cafe={row.original?.Cafe5_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe6_ready_status",
        header: selectedCafeName[5],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[5]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe6_ready}
              cafe={row.original?.Cafe6_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe7_ready_status",
        header: selectedCafeName[6],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[6]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe7_ready}
              cafe={row.original?.Cafe7_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe8_ready_status",
        header: selectedCafeName[7],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[7]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe8_ready}
              cafe={row.original?.Cafe8_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe9_ready_status",
        header: selectedCafeName[8],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[8]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe8_ready}
              cafe={row.original?.Cafe8_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe10_ready_status",
        header: selectedCafeName[9],
        Header: (
          <Custom_ColumnHeader selectedCafe={selectedCafeName[9]?.toString()} />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe9_ready}
              cafe={row.original?.Cafe9_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe11_ready_status",
        header: selectedCafeName[10],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[10]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe10_ready}
              cafe={row.original?.Cafe10_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe12_ready_status",
        header: selectedCafeName[11],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[11]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe12_ready}
              cafe={row.original?.Cafe12_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe13_ready_status",
        header: selectedCafeName[12],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[12]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe13_ready}
              cafe={row.original?.Cafe13_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe14_ready_status",
        header: selectedCafeName[13],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[13]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe14_ready}
              cafe={row.original?.Cafe14_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe15_ready_status",
        header: selectedCafeName[14],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[14]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe15_ready}
              cafe={row.original?.Cafe15_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe16_ready_status",
        header: selectedCafeName[15],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[15]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe16_ready}
              cafe={row.original?.Cafe16_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe17_ready_status",
        header: selectedCafeName[16],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[16]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe17_ready}
              cafe={row.original?.Cafe17_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe18_ready_status",
        header: selectedCafeName[17],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[17]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe17_ready}
              cafe={row.original?.Cafe17_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe19_ready_status",
        header: selectedCafeName[18],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[18]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe19_ready}
              cafe={row.original?.Cafe19_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe20_ready_status",
        header: selectedCafeName[19],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[19]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe20_ready}
              cafe={row.original?.Cafe20_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe21_ready_status",
        header: selectedCafeName[20],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[20]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe21_ready}
              cafe={row.original?.Cafe21_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe22_ready_status",
        header: selectedCafeName[21],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[21]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe22_ready}
              cafe={row.original?.Cafe22_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe23_ready_status",
        header: selectedCafeName[22],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[22]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe23_ready}
              cafe={row.original?.Cafe23_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe24_ready_status",
        header: selectedCafeName[23],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[23]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe24_ready}
              cafe={row.original?.Cafe24_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe25_ready_status",
        header: selectedCafeName[24],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[24]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe25_ready}
              cafe={row.original?.Cafe25_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe26_ready_status",
        header: selectedCafeName[25],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[25]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe26_ready}
              cafe={row.original?.Cafe26_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe27_ready_status",
        header: selectedCafeName[26],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[26]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe27_ready}
              cafe={row.original?.Cafe27_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe28_ready_status",
        header: selectedCafeName[27],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[27]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe27_ready}
              cafe={row.original?.Cafe27_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe29_ready_status",
        header: selectedCafeName[28],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[28]?.toString()}
          />
        ),

        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe29_ready}
              cafe={row.original?.Cafe29_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
      {
        accessorKey: "cafe30_ready_status",
        header: selectedCafeName[29],
        Header: (
          <Custom_ColumnHeader
            selectedCafe={selectedCafeName[29]?.toString()}
          />
        ),
        Cell: ({ renderedCellValue, row }) => (
          <>
            <FormatedDate
              date={row.original?.cafe30_ready}
              cafe={row.original?.Cafe30_Name}
              date_ipc_updated={row.original?.server_ready}
            />
          </>
        ),
      },
    ],
    [conlumns_amount.current]
  );

  //----------------- component func
  const formatDate = (x: ReactNode) => {
    let result = "";
    if (x === null || x === undefined) {
      result = "NA";
    } else if (x?.toString()?.includes("Updating")) {
      result = `Updating...`;
    } else {
      result = `${x} , ${moment(x?.toString()).fromNow()}`;
    }

    return result;
  };

  type FormatedDate_TYPE = {
    date: string;
    cafe?: string;
    game_id?: string;
    game_name?: string;
    game_category?: string;
    date_ipc_updated?: string;
  };
  const FormatedDate = (x: FormatedDate_TYPE) => {
    let result = <>---</>;

    if (x?.date === null || x?.date === undefined) {
      result = (
        <>
          <b>NA</b>
        </>
      );
    } else if (x?.date?.toString()?.includes("Updating")) {
      result = <div className="tbl_updating">Updating...</div>;
    } else {
      result = (
        <>
          <div className="glo_flex_row">
            <b>
              {moment(x?.date).diff(moment(x?.date_ipc_updated), "minute") <
              0 ? (
                <div className="tbl_done_suspect">Done??</div>
              ) : (
                <div className="tbl_done">Done</div>
              )}
            </b>
          </div>
          <b>{moment(x?.date?.toString()).fromNow()}</b>
          <hr />
          <div>{x?.date_ipc_updated}</div>
          <div>{x?.date}</div>
          <div>{`${moment(x?.date).diff(
            moment(x?.date_ipc_updated),
            "minute"
          )} min used`}</div>

          {/* <div className="tbl_text_lightgray">{x?.date}</div> */}
        </>
      );
      //  `${x?.date} , ${moment(x?.date?.toString()).fromNow()}`;
    }

    return (
      <>
        <div>{result}</div>
        <div className="tbl_text_lightgray">{`[${x?.cafe}]`}</div>
      </>
    );
  };

  const [hidenColumns, sethidenColumns] = useState({
    game_category: false,
  });
  const [showTable, setShowTable] = useState(true);

  return (
    <div>
      {/* {DB_GameStatus?.length !== 0 ? ( */}
      <>
        <h1>Update Game Status</h1>
        {/* <Button onClick={() => get_DB_GameStatus_GameAmount()}>
          Get Game Amount
        </Button> */}
        {/* <button
          onClick={() => {
            columns.push({
              accessorKey: "id",
              header: "ID",
              size: 20,
              Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
            });
            conlumns_amount.current = columns.length;
            console.log("columns.length", columns.length);
            console.log("columns.length", conlumns_amount.current);
          }}
        >
          add column
        </button> */}

        <MantineReactTable
          columns={columns?.filter((x) => {
            return x.header !== undefined;
          })}
          data={DB_GameStatus}
          state={{ isLoading: DB_GameStatus?.length === 0 }}
          // enableGrouping
          enableStickyHeader
          // enableStickyFooter
          enableColumnActions={false}
          initialState={{
            expanded: true, //expand all groups by default
            // grouping: ["game_category"], //an array of columns to group by by default (can be multiple)
            pagination: { pageIndex: 0, pageSize: 100 },
            // sorting: [{ id: "id", desc: false }], //sort by state by default
            density: "xs",
            // showColumnFilters: true,
            showGlobalFilter: true,
            columnVisibility: hidenColumns,
            columnPinning: {
              left: [
                // "id",
                "game_name",
                //  "game_category",
                // "server_ready",
              ],
            },
          }}
          // positionGlobalFilter="left"
          enableColumnResizing
          columnResizeMode="onChange" //default
          // mantineTableBodyProps={{
          //   sx: {
          //     //stripe the rows, make odd rows a darker color
          //     "& tr:nth-of-type(odd)": {
          //       backgroundColor: "#f5f5f5",
          //     },
          //   },
          // }}
          positionPagination="both"
          mantinePaginationProps={{
            rowsPerPageOptions: ["30", "100", "500", "1000"],
          }}
        />
      </>
      {/* ) : ( */}
      {/* "Loading..." */}
      {/* )} */}
      {/* </div> */}/
    </div>
  );
};

export default Page_GameStatusLog;
